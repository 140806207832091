import React from 'react';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';
import { AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';

import { EveryActionFormAnchor } from './EveryActionDonateFormAnchor.component';
import { initialize } from './everyActionFormInitializer';
import { Widgets } from './widgets';
import { UpsellModal } from './UpsellModal';
import { FastAction } from './fastAction';
import './EveryActionDonateForm.scss';
import * as Styled from './EveryActionDonateForm.styles';
import { GlobalEADonateFormInputStyles } from './EveryActionDonateForm.styles';
import enUsDictionary from './EveryActionDonateForm.dictionary.en-US';

import { LoadingContext } from '+/contexts';
import { UserContextInterface } from '+/contexts/UserContext';
import params from '+/utils/queryParams';
import { ColorTheme } from '+/components/ColorTheme';
import analyticsEvent from '+/utils/analyticsEvent';
import * as StyledForm from '+/styles/styledComponents/Form.styles';
import { PaymentMethodLogos } from '+/components/PaymentMethodLogos';
import { isPrerendering, notInIFrame } from '+/utils/environment';
import { addToDictionary, t } from '+/utils/textDictionary';

addToDictionary(enUsDictionary);

const justBareForm = params.bare;

const onError = (): void => {
  // Reload the page with query param specifying bare form.
  const { protocol, host, pathname, search } = window.location;
  window.location.href = `${protocol}//${host}${pathname.replace(/\/$/, '')}${
    search ? `${search}&bare=true` : '?bare=true'
  }`;
};

const relevantFormId = (donateFormData: DonateFormComponent) => {
  let isUS = !donateFormData.internationalFormId; // default setting.
  if (donateFormData.internationalFormId) {
    const startingCurrency = params.currency || donateFormData.defaultCurrency;
    isUS = !!donateFormData.usFormId && startingCurrency === 'USD';
  }
  if (isUS) return donateFormData.usFormId;
  return donateFormData.internationalFormId;
};

export class EveryActionDonateForm extends React.Component<{
  donateFormData: DonateFormComponent;
  advanceFormSequence?: () => void;
  userContext: UserContextInterface;
  loadingContext;
}> {
  state = {
    controller: null,
    loaded: false,
    submitted: false,
    upsellModalOptOut: false,
    upsellModalOpen: false,
    count: 0,
  };

  componentDidMount() {
    const loadingContext = this.context;
    const { donateFormData, advanceFormSequence, userContext } = this.props;
    let markLoadFinished = () => {};

    if (loadingContext.addLoadable) {
      loadingContext.addLoadable(
        new Promise<void>((resolve, reject) => {
          markLoadFinished = () => resolve();
          setTimeout(reject, 10000);
        }),
      );
    }

    if (justBareForm) {
      window.nvtag_callbacks = {
        alterFormDefinition: [markLoadFinished],
      };
      return;
    }

    const callback = controller => {
      this.setState({ controller, loaded: true });
      markLoadFinished();
    };

    const markSubmitted = () => {
      this.setState({ submitted: true });
      analyticsEvent({
        triggerAction: 'Submitted',
        targetElement: 'EveryActionForm',
        targetElementVariation: 'Donation',
        simpleName: 'Donation successfully submitted',
        context: {
          amount: this.state.controller.getSelectedAmount(),
          recurring: this.state.controller.getMonthly(),
        },
      });
      analyticsEvent({
        triggerAction: `Submitted ${this.state.controller.getMonthly() ? 'Recurring' : 'One-time'}`,
        targetElement: 'EveryActionForm',
        targetElementVariation: 'Donation',
        simpleName: `${
          this.state.controller.getMonthly() ? 'Recurring' : 'One-time'
        } donation successfully submitted`,
        context: {
          amount: this.state.controller.getSelectedAmount(),
        },
      });
      Cookies.set('isDonor', 'true', { expires: 3650 });
      const clarityDiv = document.createElement('div');
      clarityDiv.innerHTML = `<div class="asa" data-c="${
        this.state.controller.getMonthly() ? 'Donate-monthly' : 'Donate'
      }" data-v="${this.state.controller.getSelectedAmount()}" data-ct="${
        this.state.controller.getMonthly() ? 'md' : 'd'
      }" data-resource="${window.location.href.replace(/\/$/, '')}"></div>`;
      document.body.appendChild(clarityDiv);

      const userData = {
        ...userContext.userData,
        prefix: this.state.controller.getPrefix(),
        firstName: this.state.controller.getFirstName(),
        middleName: this.state.controller.getMiddleName(),
        lastName: this.state.controller.getLastName(),
        suffix: this.state.controller.getSuffix(),
        emailAddress: this.state.controller.getEmailAddress(),
        country: this.state.controller.getCountry(),
        postalCode: this.state.controller.getPostalCode(),
        mobilePhone: this.state.controller.getMobilePhone(),
        donationAmount: `${this.state.controller.getTotalAmountWithCurrencySymbol()}${
          this.state.controller.getMonthly() ? t('PerMonth.Full') : ''
        }`,
      };

      userContext.setUserData(userData);

      // If this form is in a sequence and isn't the last form, advance sequence instead of navigating to Thank You page
      if (advanceFormSequence) {
        advanceFormSequence();
      } else {
        this.navigateToThankYouPage();
      }
    };

    initialize(donateFormData, markSubmitted, callback, onError);

    setInterval(() => this.checkForUpdates(), 30);
    if (typeof window !== 'undefined') {
      window.dataLayer.push({ event: 'optimize.form-container.activate' });
    }

    analyticsEvent({
      targetElement: 'EveryActionForm',
      targetElementVariation: 'Donation',
      triggerAction: 'Load',
      simpleName: 'Load EveryAction donation form',
      options: { isDonor: Cookies.get('isDonor') === 'true' ? 'true' : 'unknown' },
    });
  }

  render() {
    const { loaded, controller, submitted, upsellModalOpen, upsellModalOptOut } = this.state;
    const { donateFormData } = this.props;

    // Get upsell image, fallback to hero image
    return (
      // Render form, then widgets once loaded
      <ColorTheme colorTheme={donateFormData.colorTheme}>
        <Helmet>
          <link rel="preconnect" href="https://d3rse9xjbp8270.cloudfront.net" />
          {!isPrerendering && !('nvtag' in window) && (
            <script src="https://d3rse9xjbp8270.cloudfront.net/at.js" />
          )}
        </Helmet>
        <GlobalEADonateFormInputStyles />
        <Styled.GlobalFastActionSignUpModalStyles />
        <StyledForm.Container className="donate">
          <StyledForm.Body>
            <StyledForm.Section bare={!!justBareForm}>
              <Styled.AnchorContainer
                className={[
                  'js-donate-form',
                  controller?.isContactInformationPrefilled() ? 'hideContactInfoFields' : null,
                  controller?.isContactInformationPrefilled() &&
                  controller?.isMobilePhonePrefilled()
                    ? 'hideMobilePhoneField'
                    : null,
                ]
                  .filter(Boolean)
                  .join(' ')}
              >
                <div className="form">
                  {!notInIFrame && !submitted && loaded && !justBareForm && (
                    <FastAction isMini controller={controller} />
                  )}
                  {!submitted && !isPrerendering && (
                    <EveryActionFormAnchor formId={relevantFormId(donateFormData)} />
                  )}
                  {!submitted && loaded && !justBareForm && (
                    <Widgets
                      onError={onError}
                      controller={controller}
                      openModal={this.openModal}
                      upsellModalOptOut={upsellModalOptOut}
                    />
                  )}
                </div>
              </Styled.AnchorContainer>
            </StyledForm.Section>
          </StyledForm.Body>
        </StyledForm.Container>
        {loaded && controller && !controller.onFinalStep() && <PaymentMethodLogos />}
        <AnimatePresence>
          {upsellModalOpen && (
            <UpsellModal
              controller={controller}
              closeModal={this.closeModal}
              recordOptOut={() => this.setState({ upsellModalOptOut: true })}
              upsellModalData={donateFormData.upsellModal}
              fallbackUpsellImage={donateFormData.supportingImage}
            />
          )}
        </AnimatePresence>
      </ColorTheme>
    );
  }

  navigateToThankYouPage = () => {
    const { donateFormData, userContext } = this.props;
    if (!donateFormData.thankYouPage) {
      return;
    }
    navigate(`/${donateFormData.thankYouPage.slug}`, {
      state: {
        firstName: userContext.userData.firstName,
        donationAmount: userContext.userData.donationAmount,
        emailAddress: userContext.userData.emailAddress,
      },
    });
  };

  checkForUpdates = () => {
    const { controller, count } = this.state;
    if (!controller) {
      return;
    }
    if (controller.areNewUpdates()) {
      controller.checkCredsForRecurringDonor();
      this.setState({ count: count + 1 });
    }
  };

  openModal = () => {
    this.setState({ upsellModalOpen: true });
  };

  closeModal = () => {
    this.setState({ upsellModalOpen: false });
  };

  static contextType = LoadingContext;
}
