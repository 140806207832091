import styled, { css } from 'styled-components';

const NOTCH_HEIGHTS = {
  center: '8.75vw',
  left: '10.25vw',
  right: '10.25vw',
};

const PADDING_HEIGHTS = {
  normal: '4.5rem',
  large: '8rem',
};

export const Section = styled.section<SectionComponentStyleProps>`
  position: relative;
  z-index: 1;
  color: var(--color-neutral-800);
  margin-top: -1px;

  ${props =>
    !!props.textAlignment &&
    css`
      text-align: ${props.textAlignment};
    `}

  ${props =>
    !!props.colorTheme &&
    css`
      background: var(--color-theme-base);
      color: var(--color-theme-base-inverse);
      border-color: var(--color-theme-accent);
    `};

  ${props =>
    (!!props.padding || !!props.notch) &&
    css`
      padding-top: calc(
        0rem ${props.padding && `+ ${PADDING_HEIGHTS[props.padding]}`}
          ${props.notch && `+ ${NOTCH_HEIGHTS.center}`}
      );
    `};

  ${props =>
    !!props.padding &&
    css`
      padding-bottom: ${PADDING_HEIGHTS[props.padding]};
    `};

  ${props =>
    !!props.notch &&
    css`
      clip-path: polygon(50% ${NOTCH_HEIGHTS.center}, 100% 0, 100% 100%, 0 100%, 0 0);
      // Chrome has issues with clip path that adding a z translate helps.
      transform: translateZ(0);
      margin-top: -${NOTCH_HEIGHTS.center};
    `}

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    ${props =>
      // eslint-disable-next-line indent
      !!props.notch &&
      css`
        margin-top: -${NOTCH_HEIGHTS[props.notch]};
      `}
    ${props =>
      // eslint-disable-next-line indent
      props.notch === 'left' &&
      css`
        clip-path: polygon(
          0 5.25vw,
          32% ${NOTCH_HEIGHTS[props.notch]},
          100% 0,
          100% 100%,
          0 100%,
          0 0
        );
        transform: translateZ(0);
      `}
    ${props =>
      // eslint-disable-next-line indent
      props.notch === 'right' &&
      css`
        clip-path: polygon(
          0 0,
          67% ${NOTCH_HEIGHTS[props.notch]},
          100% 5.25vw,
          100% 100%,
          0 100%,
          0 0
        );
        transform: translateZ(0);
      `}
  }
`;

export const SectionContents = styled.div<{ width: string }>`
  ${props => {
    switch (props.width) {
      case 'small':
        return css`
          width: 90%;
          max-width: 41.5rem;
          margin-left: auto;
          margin-right: auto;
        `;
      case 'medium':
        return css`
          width: 90%;
          max-width: 59rem;
          margin-left: auto;
          margin-right: auto;
        `;
      case 'large':
        return css`
          width: 90%;
          max-width: 86.25rem;
          margin-left: auto;
          margin-right: auto;
        `;
      case 'full':
        return css`
          width: 90%;
        `;
      default:
        return css`
          width: 100%;
        `;
    }
  }}
`;

interface BackgroundMediaProps {
  colorTheme?: string;
  notch?: string;
  padding?: string;
}

export const BackgroundMedia = styled.div<BackgroundMediaProps>`
  position: relative;

  margin-top: calc(
    ${
      /* eslint-disable indent */
      props => {
        let marginTop = '0rem';
        marginTop += props.padding ? ` - ${PADDING_HEIGHTS[props.padding]}` : '';
        marginTop += props.notch ? ` - ${NOTCH_HEIGHTS.center}` : '';
        return marginTop;
      }
      /* eslint-enable indent */
      // eslint-disable-next-line indent
    }
  );

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    ${props =>
      // eslint-disable-next-line indent
      (props.notch === 'left' || props.notch === 'right') &&
      css`
        margin-top: calc(
          0rem - ${NOTCH_HEIGHTS[props.notch]}
            ${props.padding ? ` - ${PADDING_HEIGHTS[props.padding]}` : ''}
        );
      `}
  }

  ${props =>
    !!props.colorTheme &&
    css`
      margin-bottom: max(-20rem, -17.5%);
      z-index: -1;

      &:after {
        position: absolute;
        content: '';
        height: 50%;
        width: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(transparent, var(--color-theme-base));
      }
    `}

  img, video {
    width: 100%;
  }
`;

export const PhotoCredit = styled.div<{ notch: string; colorTheme: string }>`
  color: var(--color-neutral-450);
  font-size: 0.5625rem;
  line-height: 1;
  position: absolute;
  right: 1em;
  top: 1em;
  transform: rotate(-90deg);
  transform-origin: bottom right;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 0.6875rem;
  }

  ${props =>
    props.notch &&
    props.notch !== 'center' &&
    css`
      top: auto;
      bottom: 1em;
      transform: rotate(-90deg) translate(100%);
    `}

  ${props =>
    props.notch &&
    props.notch !== 'center' &&
    props.colorTheme &&
    css`
      color: ${props.colorTheme === 'default'
        ? 'var(--color-neutral-600)'
        : 'var(--color-neutral-350)'};
      z-index: 2;
    `}
`;

export const BackgroundImage = styled.div``;
export const BackgroundVideo = styled.div``;
